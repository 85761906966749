import { useNavigate, useParams } from "react-router-dom"
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import { useEffect, useState } from "react";
import AuthService from "../../Api/Api_Services/AuthService";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import { $ } from "react-jquery-plugin";
import { alertSuccessMessage } from "../../Utils/CustomAlertMessage";
import Countdown from "react-countdown";

const LaunchpadDetailPage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [projectDetails, setProjectDetails] = useState({});
    const [commitAmount, setCommitAmount] = useState('');
    const [commitDetails, setCommitDetails] = useState({});

    const getProjectDetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.get_single_project(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setProjectDetails(result?.data)
                totalCommits();
            } else {
                navigate('/Launchpad')
            }
        })
    };

    const totalCommits = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.totalCommits(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setCommitDetails(result?.data)
            }
        })
    };

    const updateCommit = async () => {
        if ((!commitAmount || commitAmount == 0)) {
            return
        }
        LoaderHelper.loaderStatus(true);
        const result = await AuthService.updateCommit(id, commitAmount)
        if (result?.success) {
            LoaderHelper.loaderStatus(false);
            alertSuccessMessage(result?.message)
            setCommitAmount('')
            totalCommits()
            $("#commit_modal").modal("hide");
        }
    };

    const updateCommitModal = () => {
        $("#commit_modal").modal("show");
    };

    useEffect(() => {
        getProjectDetails();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <section className="about_top lp_sec lp_details" >
                <div className="container" >
                    <div className="row g-md-5  " >
                        <div className="col-lg-6" >
                            <h2 className="" >
                                {Object.keys(projectDetails).length > 0 && <><img alt="GapxBit" src={`${ApiConfig.baseUrl}${projectDetails?.token_image_path}`} width="60" className="img-fluid" />  {projectDetails?.projectName} ({projectDetails?.projectSymbol}) </>}
                            </h2>
                            <div className="d-flex align-items-center gap-2 mt-2 " >
                                <span className="badge badge-primary" > Live </span>
                            </div>
                            <div className="earn-info_infoWrapper-activity__o6kPE">
                                <div className="earn-info_info-activity__OTCYI">
                                    <div className="earn-info_infoItem-activity___IScS">
                                        <div className="earn-info_itemLabel-activity__SQp8u text-muted"><span className="earn-info_tips-activity__5WSHY">Total Prize Pool</span></div>
                                        <div className="earn-info_itemValue-activity__qYQBv"> {projectDetails?.totalSupply} {projectDetails?.projectSymbol} </div>
                                    </div>
                                    <div className="earn-info_infoItem-activity___IScS">
                                        <div className="earn-info_itemLabel-activity__SQp8u  text-muted"><span className="earn-info_tips-activity__5WSHY"> Total Commited  </span></div>
                                        <div className="earn-info_itemValue-activity__qYQBv"> {projectDetails?.totalCommitted} {commitDetails?.commitCurrency} </div>
                                    </div>
                                </div>
                            </div>

                            <div className="css-1fr1brr ">
                                <label className="text-muted text-start" >Commitment stage ends in</label>
                                {projectDetails?.endDate && <Countdown
                                    date={new Date(projectDetails?.endDate).getTime()}
                                    renderer={props => <div className="css-fj1yy5">
                                        <span>{props.days}<small>Days</small></span>
                                        <span>{props.hours}<small>Hrs</small></span>
                                        <span>{props.minutes}<small>Min.</small></span>
                                        <span>{props.seconds}<small>Sec.</small></span>
                                    </div>}
                                />}
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                <div className="ant-card-body">
                                    <div className="lp_left" >
                                        <div className="css-13to1co d-flex align-items-center justify-content-between">
                                        GapxBit Commitment Details
                                            <div className="lp_nav" >
                                                <a href={`/CommitDetails/${id}`} className="btn btn-link p-0"> <i className="ri-exchange-dollar-line"></i> Reward History   </a>
                                            </div>
                                        </div>
                                        <div className="css-leoum0">
                                            <div className="css-13f9r66">
                                                <div data-bn-type="text" className="css-vurnku text-muted"> My Actual Rewards</div>
                                                <div data-bn-type="text" className="css-153t1uw "> {commitDetails?.actualRewards} {projectDetails?.projectSymbol}</div>
                                            </div>
                                            <div className="css-13f9r66">
                                                <div data-bn-type="text" className="css-vurnku text-muted"> My Estimated  Rewards  </div>
                                                <div data-bn-type="text" className="css-153t1uw"> {commitDetails?.estimatedRewards} {projectDetails?.projectSymbol} </div>
                                            </div>
                                            <div className="css-13f9r66">
                                                <div data-bn-type="text" className="css-vurnku text-muted"> My Commited  Quantity  </div>
                                                <div data-bn-type="text" className="css-153t1uw"> {commitDetails?.totalCommittedQuantity} {commitDetails?.commitCurrency} </div>
                                            </div>
                                            <div className="css-13f9r66">
                                                <div data-bn-type="text" className="css-vurnku text-muted"> My Valid Commited  </div>
                                                <div data-bn-type="text" className="css-153t1uw">  {commitDetails?.validQuantity} {commitDetails?.commitCurrency} </div>
                                            </div>
                                        </div>
                                        <div className="css-1fr1brr fr1brr1">
                                            <div data-bn-type="text" className="css-fj1yy5">
                                                <span className="text-muted" >
                                                    My Maximum Committable
                                                </span>
                                                <span className="text-end" >
                                                    {commitDetails?.wallet?.balance} {commitDetails?.commitCurrency}
                                                </span>
                                            </div>
                                        </div>
                                        <button className="btn btn-gradient w-100 justify-content-center  mt-4" onClick={updateCommitModal}>
                                            <span > Update GapxBit Commit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" launchpad_sec live_prices mt-0 market_prices market_update_sec market_update_table  ">
                <div className="container">
                    <div className="lp_row" >
                        <div className="row" >
                            <div className=" col-lg-12 lp_title" >
                                <h3> GapxBit  Tier Mechanism </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center gx-md-5 ">
                        <div className="col-lg-5" >
                            <div className="table-responsive" >
                                <table className="table table-borderd" >
                                    <thead>
                                        <tr>
                                            <th className="text-center" >Commited Quantity </th>
                                            <th className="text-center" >   Commitment Coefficient  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center" > 1000<strong> &#x2264;</strong> X<strong>&#x3c;</strong>    3,000 </td>
                                            <td className="text-center" > 1 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center" > 3000  <strong>&#x2264;</strong>X <strong>&#x3c;</strong>   5000 </td>
                                            <td className="text-center" > 1.05 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center" > 5000 <strong>&#x2264; </strong> X<strong>&#x3c;</strong>    10,000 </td>
                                            <td className="text-center" > 1.1 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center"> 10,000 <strong> &#x2264; </strong>   X<strong>&#x3c;</strong>    320,000 </td>
                                            <td className="text-center" > 1.15 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center" > 20,000<strong> &#x2264; </strong>X <strong>&#x3c;</strong>     50,000 </td>
                                            <td className="text-center" > 1.2 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center" > 50,000<strong> &#x2264; </strong> X<strong>&#x3c;</strong>   100,000 </td>
                                            <td className="text-center" > 1.25 </td>
                                        </tr>
                                        <tr>
                                            <td className="d-flex gap-2 justify-content-center"> 100,000 <strong>&#x2264;</strong> X <strong>&#x3c;</strong>     500,000 </td>
                                            <td className="text-center" > 1.3 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-7" >
                            <div className="lp_row" >
                                <div className="row" >
                                    <div className=" col-lg-12 lp_title" >
                                        <h3 className="mb-3" >  Rule Description </h3>

                                        <p className="text-muted" >
                                            The greater the GapxBit commitment, the higher the corresponding commitment coefficient, and the higher the share of rewards.
                                        </p>
                                        <p className="text-muted" >
                                            E.g. Suppose there are only two participants, A and B, in the event. A commits 2,999, and the coefficient is 1; B commits 3,000 and the coefficient is 1.05.
                                        </p>
                                        <p className="text-muted" >
                                            A's reward = 2,999 * 1 / (2,999 * 1 + 3,000 * 1.05)
                                        </p>
                                        <p className="text-muted" >
                                            B's reward = 3,000 * 1.05 / (2,999 * 1 + 3,000 * 1.05)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*Update Commit GapxBit Brake */}
            <div className="modal fade" id="commit_modal" tabIndex="-1" aria-labelledby="commit_modalLaebl" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">
                                Update Commit
                            </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                                <i className="ri-close-fill"></i>
                            </button>
                        </div>
                        <div className="modal-body px-5 pb-8">
                            <div className="st_body">
                                <div className="form-group mb-1">
                                    <label htmlFor="phase">Enter Amount</label>
                                    <input type="number" name="amount_val" placeholder="Enter Amount" onWheel={(e) => e.target.blur()} onChange={(e) => setCommitAmount(e.target.value)} value={commitAmount} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between gap-5 mt-3">
                                <button type="button" className="btn btn-gradient btn-large w-100 justify-content-center" onClick={updateCommit} id={((!commitAmount || commitAmount == 0) && "inValid")} >
                                    <span>Commit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default LaunchpadDetailPage