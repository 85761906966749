import React from "react";

const ComingSoonPage = () => {
    return (
        <section style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center' // Center the content horizontally
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <div>
                    <img 
                        alt="Coming Soon" 
                        src="/images/coming_soon.svg" 
                        style={{ 
                            maxWidth: '70%', // Adjust the size as needed
                            height: 'auto' 
                        }} 
                    />
                </div>
            </div>
        </section>
    );
}

export default ComingSoonPage;
