// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TVChartContainer {
	height: 100%;
	width: 100%;
}

#TVChartContainer>* {
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Utils/TVChartContainer/index.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":["#TVChartContainer {\n\theight: 100%;\n\twidth: 100%;\n}\n\n#TVChartContainer>* {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
