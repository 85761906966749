import React, { useEffect } from 'react'

const Affiliate = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
    
    <section className="about_top tax_VIP" >
      <div className="container" >
        <div className="row g-md-5 align-items-center" >
          <div className="col-lg-7" >
             
             <h2 className='text-gradient' >
             GapxBit VIP Center
             </h2>

             <p  className='text-white'  >
             24/7 VIP Customer Service with Personalized Assistance and Over 10 Exclusive Privileges

             </p>
            
          </div>
          <div className="col-lg-5" >
              <img alt="" src="/images/vip_card.svg"  className=" w-100 img-fluid" />
          </div>
        </div>
      </div>
    </section>

    <section className="vip_table_sec" >
      <div className="container" >
        <div className="vip_card" >
          <div className='vip_card_body'>
            <div className='table-responsive' >
              <table className='table  table-striped' >
                <thead className="" >
                  <tr>
                    <th   >
                    VIP Level and Key Benefits
                    </th>
                    <th className='bg_sliver' >
                    Level 1 – Silver VIP
                    </th>
                    <th className='bg_gold' >
                    Level 2 – Gold VIP
                    </th>
                    <th className='bg_platinum' >
                    Level 3 – Platinum VIP 
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td > Exclusive Trading Fees </td>
                    <td> <i className="ri-check-fill"></i></td>
                    <td>  <i className="ri-check-fill"></i></td>
                    <td> <i className="ri-check-fill"></i> </td>
                  </tr> 
                  <tr>
                    <td> GapxBit VIP Icon </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                  </tr>
                  <tr>
                    <td> GapxBit Industry Analysis Report </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                  </tr>
                  <tr>
                    <td> Holiday & Birthday Gifting</td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                  </tr>
                  <tr>
                    <td> Exclusive VIP Events </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                  </tr>
                  <tr>
                    <td> 24/7 VIP Customer Service </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                    <td>  <i className="ri-check-fill"></i>  </td>
                  </tr>
                  <tr>
                    <td> Withdrawal Limit Increase </td>
                    <td>  </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr>
                  <tr>
                    <td> GapxBit Affiliate Program </td>
                    <td>  </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr>
                  <tr>
                    <td> Prioritized Invitation to GapxBit Offline Events </td>
                    <td>  </td>
                    <td> <i className="ri-check-fill"></i> </td>
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr>
                  <tr>
                    <td> Priority in New Product Beta Test </td>
                    <td>  </td>
                    <td>    </td> 
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr>
                  <tr>
                    <td> High-quality Projects Subscription </td>
                    <td>  </td>
                    <td>  </td> 
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr>
                  <tr>
                    <td> GapxBit·VIP Private Seminar </td>
                    <td>  </td>
                    <td>    </td> 
                    <td>  <i className="ri-check-fill"></i>  </td> 
                  </tr> 
                </tbody>
                <tfoot className="" >
                    <tr>
                      <td className='bg_gold' >
                      To become a Gold VIP, you need to increase your total assets by
                      </td>
                      <td className='bg_gold' >
                      300,000 USDT
                      </td>
                      <td className='bg_gold'>
                      500,000 USDT
                      </td>
                      <td className='bg_gold' >
                      1,000,000 USDT
                      </td>
                    </tr>
                  </tfoot>
              </table>
            </div>
             
          </div>
        </div>
      </div>


      
    </section>


    <section className='tbc_sec' >
      <div className='container  ' >
        <h3>
        VIP Benefits Criteria
        </h3>
        <div className='table-responsive' >
          <table className='table  text-center' >
            <thead className="" >
              <tr>
                <th> VIP Privileges   </th>
                <th>  VIP Level Retention Period (Days)  </th>
                <th> Total Assets  </th>
              </tr>
            </thead>
            <tbody> 
              <tr>
                <td> Silver VIP  </td>
                <td> 20 </td>
                <td> ≥300,000 USDT </td>  
              </tr>
              <tr>
                <td> Diamond VIP  </td>
                <td> 40 </td>
                <td> ≥500,000 USDT </td>  
              </tr>
              <tr>
                <td> Premium VIP  </td>
                <td> 60 </td>
                <td> ≥1,000,000 USDT </td>  
              </tr> 
            </tbody> 
          </table>
        </div>
      </div>  
    </section>

    <section className='tbc_sec' >
      <div className='container  ' >
        <h3>
        Specifics of VIP Benefits:
        </h3>
        

        <p>
        1. Your total asset snapshot will be captured between 00:00 and 24:00 (UTC+8) on the previous day for calculation purposes.
        </p>
        <p>  2. VIP levels and associated benefits will be assessed and updated daily between 00:00 and 08:00 (UTC+8). Due to the
          substantial data volume processed, there may be a slight delay in VIP level updates. Please refer to the actual update time
          for accuracy. 
          </p>

          <p>   3. Once your latest VIP level takes effect, it will be maintained during the retention period and will not be downgraded. If you
          meet the upgrade conditions within this period, your VIP level will automatically elevate. You can enjoy VIP privileges,
          including special trading fees, throughout the retention period. 
          </p>

          <p>   4. To configure your special trading fee settings, kindly get in touch with your dedicated VIP manager for upgrades and
          adjustments. If your total assets do not meet the corresponding VIP criteria for seven consecutive days, the special trading
          fee will be discontinued. 
          </p>
         
      </div>  
    </section>

    <section className='tbc_sec' >
      <div className='container  ' >
        <h3>
        Contact Us
        </h3>
        

        <p>
        If your assets on GapxBit total ≥ 300,000 USDT, you are eligible to apply for VIP status. To do so, please reach out to us using one of the following methods:
        </p>
        <p>  Send an email to  <a className='text-underline text-gradient' href="mailto:vip@GapxBitglobal.io" > <b> vip@GapxBitglobal.io </b> </a>  for any inquiries or questions. When reaching out, kindly include the following details in your message
          </p>
          

          <p> Your name </p>
          <p>    Contact number</p>
          <p>    Telegram ID</p>
          <p>    Your country (so that we can assign a VIP specialist who speaks your language)</p>
          <p>   Any specific needs or suggestions you may have</p>
          <p>   We look forward to assisting you on your VIP journey.</p>

           
        
      </div>  
    </section>



    
    
    </>
  )
}

export default Affiliate
