import { useNavigate } from "react-router-dom"
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { useEffect, useState } from "react";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../Utils/CustomAlertMessage";
import DataTableBase from "../../Utils/DataTable";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, FreeMode } from "swiper";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import Moment from "react-moment";
import CountdownTimer from "../../Utils/CoutdownLaunchpad";

const Launchpad = () => {
    const navigate = useNavigate()
    const [eligibiltyDetails, setEligibiltyDetails] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [pastProjectList, setPastProjectList] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});
    const [commitAmount, setCommitAmount] = useState('');
    const [activeTab, setActiveTab] = useState('Ongoing Events');
    const [rewardHistory, setRewardHistory] = useState([]);
    const minHolding = 1000;
    const holdingDuration = 30

    const userEligibility = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.userEligibility()
            if (result?.success) setEligibiltyDetails(result)
        } catch (error) { }
    };

    const getAllProject = async (status) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getAllProject(status)
            if (result?.success) {
                if (status === 2) setProjectList(result?.data)
                else if (status === 1) setPastProjectList(result?.data)
            }
        } catch (error) { }
    };

    const getRewardHistory = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getUserCommits();
            if (result?.success) setRewardHistory(result?.data?.reverse()?.map((data, index) => ({ ...data, index: index + 1 })));
        } catch (error) { }
    };

    const commitProject = async () => {
        try {
            if (commitAmount < 1000) {
                alertWarningMessage('Commit must be greater then 999')
                return
            }
            const { projectName, projectSymbol, _id } = projectDetails
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.commitProject(projectName, projectSymbol, commitAmount, _id);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message)
                $("#commit_modal").modal("hide");
                navigate(`/launchpad-details/${_id}`)
            };
        } catch (error) { }
    };

    function parseISODate(dateString) {
        const parts = dateString.split('T');
        const datePart = parts[0];
        const timePart = parts[1] ? parts[1].split('.')[0] : '00:00:00';
        const [year, month, day] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        return new Date(year, month - 1, day, hours, minutes, seconds);
    };

    const isValidToNewCommit = async (id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService?.checkCommitExistence(id);
            if (result?.success) return result.exists;
            else return null;
        } catch (error) { return null };
    };

    const checkCommittable = async (data) => {
        if (!(eligibiltyDetails?.balance >= minHolding && eligibiltyDetails?.holding_duration >= holdingDuration)) {
            alertErrorMessage("You are not eligible to participate")
            return
        };
        const isCommitExist = await isValidToNewCommit(data?._id)
        if (isCommitExist === false) {
            setProjectDetails(data)
            $("#commit_modal").modal("show");
            return
        } else if (isCommitExist === true) {
            navigate(`/launchpad-details/${data?._id}`)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const rewardColumn = [
        { name: <>Serial No.</>, wrap: true, selector: (row) => row.index, },
        { name: <>Date</>, wrap: true, selector: (row) => <Moment format='DD-MM-YY , hh:mm'>{row?.commitDate}</Moment>, },
        { name: <>Project Name</>, wrap: true, selector: (row) => row?.projectName, },
        { name: <>Commited Currency</>, wrap: true, selector: (row) => row?.commitCurrency, },
        { name: <>Commited Quantity</>, wrap: true, selector: (row) => row?.committedQuantity, },
        { name: <>Estimated Rewards</>, wrap: true, selector: (row) => row?.estimatedRewards, },
        { name: <>Valid Quantity</>, wrap: true, selector: (row) => row?.validQuantity, },
    ]

    useEffect(() => {
        userEligibility()
        getAllProject(2)
        getAllProject(1)
    }, []);

    return (
        <>
            <section className="about_top lp_sec" >
                <div className="container" >
                    <div className="row g-md-5 align-items-center" >
                        <div className="col-lg-6" >
                            <h2 style={{color:'#3d9483 '}} >
                                Launchpad
                            </h2>
                            <h4 className="text-muted" >GAPx Holder Privileges - Join and Share New Tokens</h4>
                            <div className="earn-info_infoWrapper-activity__o6kPE">
                                <div className="earn-info_info-activity__OTCYI">
                                    <div className="earn-info_infoItem-activity___IScS">
                                        <div className="earn-info_itemLabel-activity__SQp8u text-muted"><span className="earn-info_tips-activity__5WSHY">Event APY</span></div>
                                        <div className="earn-info_itemValue-activity__qYQBv">3.99%</div>
                                    </div>
                                    <div className="earn-info_infoItem-activity___IScS">
                                        <div className="earn-info_itemLabel-activity__SQp8u  text-muted"><span className="earn-info_tips-activity__5WSHY">Total Projects </span></div>
                                        <div className="earn-info_itemValue-activity__qYQBv">{projectList?.length}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="banner_memberInfo-activity__EBkrD banner_memberInfoBg1-activity__CQr0Q">
                                <div className="banner_memberInfoShadow-activity__21WMc">

                                    <img alt="ctex" src="/images/ctex-token.png" className="img-fluid" />
                                </div>
                                <div className="banner_memberInfoBgWrapper-activity__lWKfL">
                                    <div className="banner_memberRankIconWrapper-activity__xro2J">
                                        <div className="banner_memberRankOverlayBg-activity__Y3lts"></div>
                                    </div>
                                    <div className="banner_memberInfoContent-activity__bxKYP">
                                        <div className="banner_infoWrapper-activity__wabzD">
                                            <div className="banner_infoContent-activity___pNIq">
                                                <div className="effect-info_container-activity__7npes">
                                                    <div className="effect-info_noLevelWrapper-activity__UeGqT">
                                                        <div className="effect-info_noLeveltitle-activity__O1UH7">Hold GAPx to enjoy massive free new token airdrops</div>

                                                        <div className="effect-info_noLevelInfo-activity__VNUFe effect-info_infoFst-activity__crCXH text-muted">
                                                            <span className={`effect-info_noLevelCheckbox-activity__10LsZ ${eligibiltyDetails?.balance >= minHolding && "active"}`}></span>
                                                            <span className='effect-info_noLevelLabel-activity__8bM_2'>Holding ≥ 1000 GAPx </span></div>
                                                        <div className="effect-info_noLevelInfo-activity__VNUFe text-muted"><span className={`effect-info_noLevelCheckbox-activity__10LsZ ${eligibiltyDetails?.holding_duration >= holdingDuration && "active"}`}></span>
                                                            <div className="effect-info_noLevelLabel-activity__8bM_2">Holding duration ≥ 30 days</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-detail_container-activity__quaDT level-detail_containerNoLevel-activity__bBjjq effect-info_noEffectDetail-activity__1_3lv">
                                                    <div className="level-detail_boxShadow-activity__OakMa"></div>
                                                    <div className="level-detail_noLevelWrapper-activity___vocc">
                                                        <span className="level-detail_effectLabel-activity__6RIZk">
                                                            <span className="level-detail_effectLabel-activity__6RIZk level-detail_antLine-activity__iFSWE  text-muted">
                                                                Total Valid Quantity Today
                                                            </span>
                                                        </span>
                                                        <span className="level-detail_effectValue-activity__xc59s">{parseFloat(eligibiltyDetails?.balance?.toFixed(5))} <span className="text-muted mx-1" > GAPx </span>
                                                        </span></div>
                                                </div>
                                                <div className="banner_buyWrapper-activity__U6oVF">
                                                    <a href="/trade/CTEX_USDT" style={{backgroundColor:'#3d9483'}}className="banner_buyBtn-activity__IrEnP"><span className="banner_buyBtnText-activity__1YQYf">Buy GAPx</span></a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className=" launchpad_sec live_prices mt-0 market_prices market_update_sec market_update_table pb-0">
                <div className="container">
                    <div className="lp_row" >
                        <div className="row" >
                            <div className=" col-lg-6 lp_title" >
                                <h3> {activeTab} </h3>
                            </div>
                            <div className="col-lg-6" >

                                <div className="lp_nav" >
                                    <a href="#/" className={`btn btn-link p-0 ${activeTab === 'Ongoing Events' && "active"}`} onClick={() => setActiveTab('Ongoing Events')}><i className="ri-list-check"></i>Ongoing Events </a>
                                    <a href="#/" className={`btn btn-link p-0 ${activeTab === 'Past Events' && "active"}`} onClick={() => setActiveTab('Past Events')}> <i className="ri-file-list-line"></i>  Past Events </a>
                                    <a href="#/" className={`btn btn-link p-0 ${activeTab === 'Reward History' && "active"}`} onClick={() => { getRewardHistory(); setActiveTab('Reward History') }}> <i className="ri-exchange-dollar-line"></i> Reward History   </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeTab === 'Ongoing Events' ?
                        <Swiper
                            className="market_slider  pb-5"
                            spaceBetween={10}
                            scrollbar={{
                                hide: false,
                            }}
                            modules={[Scrollbar, FreeMode]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}>
                            {projectList?.length > 0 ? projectList?.map((data) => {
                                const now = new Date();
                                const start = parseISODate(data?.startDate);
                                const end = parseISODate(data?.endDate);
                                const isLive = !(now < start)
                                return (
                                    <SwiperSlide key={data?._id}>
                                        <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                            <div className="ant-card-body">
                                                <div className="lp_left" >
                                                    <div className="css-13to1co">
                                                        <img alt="GAPx" src={`${ApiConfig.baseUrl}${data?.token_image_path}`} width="50" className="img-fluid" />

                                                        <div>
                                                            {data?.projectName} ({data?.projectSymbol})
                                                            <div className="d-flex align-items-center gap-2 mt-2" >
                                                                {isLive ?
                                                                    <span className="badge badge-warning" >Ongoing</span> :
                                                                    <span className="badge badge-success" >Upcoming</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-leoum0">
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted">Total Prize Pool </div>
                                                            <div data-bn-type="text" className="css-153t1uw ">{data?.totalSupply} {data?.projectSymbol} </div>
                                                        </div>
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted"> Total Commited  </div>
                                                            <div data-bn-type="text" className="css-153t1uw"> {data?.totalCommitted} GAPx </div>
                                                        </div>
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted"> Token Price  </div>
                                                            <div data-bn-type="text" className="css-153t1uw"> {data?.tokenPrice} GAPx </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-1fr1brr">
                                                        <label className="text-muted" >Commitment stage {isLive ? "ends" : "starts"} in</label>
                                                        {/* <Countdown
                                                            date={isLive ? new Date(data?.endDate).getTime() : new Date(data?.startDate).getTime()}
                                                            renderer={props => <div className="css-fj1yy5">
                                                                <span>{props.days}<small>Days</small></span>
                                                                <span>{props.hours}<small>Hrs</small></span>
                                                                <span>{props.minutes}<small>Min.</small></span>
                                                                <span>{props.seconds}<small>Sec.</small></span>
                                                            </div>}
                                                        /> */}
                                                        <CountdownTimer startDate={data?.startDate} endDate={data?.endDate} />
                                                    </div>
                                                    {now > end ?
                                                        <div className="btn btn-dark w-100 justify-content-center  mt-4" id="inValid" >
                                                            <span> Not Eligible </span>
                                                        </div> :
                                                        isLive ?
                                                            <div href={`/launchpad-details/${data?._id}`} className="btn btn-gradient w-100 justify-content-center  mt-4" onClick={() => checkCommittable(data)}>
                                                                <span > Commit GAPx </span></div> :
                                                            <div className="btn btn-gradient w-100 justify-content-center  mt-4" id="inValid" >
                                                                <span > Commit GAPx </span>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }) :
                                <div className="favouriteData ">
                                    <img src="/images/not_found.svg" className="img-fluid" width="200px" alt="" />
                                    <small>  New Projects will live soon</small>
                                </div>
                            }

                        </Swiper>
                        : activeTab === 'Past Events' ? <Swiper
                            className="market_slider  pb-5"
                            spaceBetween={10}
                            scrollbar={{
                                hide: false,
                            }}
                            modules={[Scrollbar, FreeMode]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}>
                            {pastProjectList?.length > 0 ? pastProjectList?.map((data) => {
                                return (
                                    <SwiperSlide key={data?._id}>
                                        <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                            <div className="ant-card-body">
                                                <div className="lp_left" >
                                                    <div className="css-13to1co">
                                                        <img alt="GAPx" src={`${ApiConfig.baseUrl}${data?.token_image_path}`} width="50" className="img-fluid" />

                                                        <div>
                                                            {data?.projectName} ({data?.projectSymbol})
                                                            <div className="d-flex align-items-center gap-2 mt-2" >
                                                                <span className="badge badge-success" >Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="css-leoum0">
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted">Total Prize Pool </div>
                                                            <div data-bn-type="text" className="css-153t1uw ">{data?.totalSupply} {data?.projectSymbol} </div>
                                                        </div>
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted"> Total Commited  </div>
                                                            <div data-bn-type="text" className="css-153t1uw"> {data?.totalCommitted} GAPx </div>
                                                        </div>
                                                        <div className="css-13f9r66">
                                                            <div data-bn-type="text" className="css-vurnku text-muted"> Token Price  </div>
                                                            <div data-bn-type="text" className="css-153t1uw"> {data?.tokenPrice} GAPx </div>
                                                        </div>
                                                    </div>

                                                    <div className="btn btn-gradient w-100 justify-content-center  mt-4" onClick={() => { navigate(`/CommitDetails/${data?._id}`); window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                                                        <span > View Commits</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }) :

                                <div className="favouriteData ">
                                    <img src="/images/not_found.svg" className="img-fluid" width="200px" alt="" />
                                    <small>No Data Available</small>
                                </div>}

                        </Swiper> :
                            <div className="table-responsive scroll_y launchpasTable ">
                                <DataTableBase columns={rewardColumn} data={rewardHistory} pagination pointerOnHover onRowClicked={(row) => { navigate(`/CommitDetails/${row?.projectId}`); window.scrollTo({ top: 100, behavior: 'smooth' }); }} />
                            </div>
                    }
                </div>
            </section >



            <section className=" hiw_sec lp_step ">
                <div className="container">
                    <div className="section-title section-title-center text-center">
                        <h2 className="text-gradient">How it work</h2>
                        <p>Follow these simple steps , and start your trading journey</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                <div className="thumb m-auto">
                                    <img src="/images/step-v11.svg" alt="nft wallet" />
                                </div>
                                <span>Step 1</span>
                                <h4 className="title text-gradient">Buy</h4>
                                <p> Buy at least 1,000 GAPx </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                <div className="thumb m-auto">
                                    <img src="/images/step-v12.svg" alt="nft wallet" />
                                </div>
                                <span>Step 2</span>
                                <h4 className="title text-gradient">Hold</h4>
                                <p>
                                    Meet the GAPx holding duration: 30 days
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
                            <div className="wallet-block text-center hiw_card">
                                <div className="thumb m-auto">
                                    <img src="/images/step-v13.svg" alt="nft wallet" />
                                </div>
                                <span>Step 3</span>
                                <h4 className="title text-gradient"> Commit </h4>
                                <p>Click [Quick Commit] to participate in the event and receive new token airdrop rewards (GAPx will not be locked up during the event)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Commit GAPx Brake */}
            <div className="modal fade" id="commit_modal" tabIndex="-1" aria-labelledby="commit_modalLaebl" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">
                                Commit now
                            </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                                <i className="ri-close-fill"></i>
                            </button>
                        </div>
                        <div className="modal-body px-5 pb-8">
                            <div className="st_body">
                                <div className="form-group mb-1">
                                    <label htmlFor="phase">Enter Amount</label>
                                    <input type="number" name="amount_val" placeholder="Enter Amount" onWheel={(e) => e.target.blur()} value={commitAmount} onChange={(e) => setCommitAmount(e.target.value)} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between gap-5 mt-3">
                                <button type="button" className="btn btn-gradient btn-large w-100 justify-content-center" onClick={commitProject}>
                                    <span>Commit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Launchpad