import React from "react";

export default function SetBuyOrderPrice({ buyprice,setbuyamount,buyOrderPrice}) {
  return (
    <div
      className="btn-group btn-group-mini  mb-3"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio10"
        autoComplete="off"
      />
      <label
        className="btn btn-outline-success"
        htmlFor="btnradio10"
        // onClick={() => {
        //   setOrderprice(10);
        // }}
        onClick={() => {
          setbuyamount(
            parseFloat(
              (
                (10) /
                (buyOrderPrice !== undefined ||
                buyOrderPrice
                  ? buyOrderPrice
                  : buyprice)
              )
            )
          );
        }}
      >
        10
      </label>
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio50"
        autoComplete="off"
      />
      <label
        className="btn btn-outline-success"
        htmlFor="btnradio50"
        onClick={() => {
          setbuyamount(
            parseFloat(
              (
                (50) /
                (buyOrderPrice !== undefined ||
                buyOrderPrice
                  ? buyOrderPrice
                  : buyprice)
              )
            )
          );
        }}
      >
        50
      </label>
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio100"
        autoComplete="off"
      />
      <label
        className="btn btn-outline-success"
        htmlFor="btnradio100"
        onClick={() => {
          setbuyamount(
            parseFloat(
              (
                (100) /
                (buyOrderPrice !== undefined ||
                buyOrderPrice
                  ? buyOrderPrice
                  : buyprice)
              )
            )
          );
        }}
      >
        100
      </label>
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio150"
        autoComplete="off"
      />
      <label
        className="btn btn-outline-success"
        htmlFor="btnradio150"
        onClick={() => {
          setbuyamount(
            parseFloat(
              (
                (150) /
                (buyOrderPrice !== undefined ||
                buyOrderPrice
                  ? buyOrderPrice
                  : buyprice)
              )
            )
          );
        }}
      >
        150
      </label>
    </div>
  );
}
