// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trade-markit-container {
  display: flex;  
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* margin: 20px; */
}
.me-2{
  margin-left: 10px;
}

.section1 {
  width: 32.9%;
  /* align-items: center; */
}
.sectionpadding{
  align-items: center;
  margin-bottom: 10px;
  border-radius:15px ;
  margin-top: 20px;
}
.th-1{
  padding: 2px 10px 2px 10px;

}

table {
  width: 100%;
  /* border-collapse: collapse; */
}

/* Responsive design for mobile view */
@media screen and (max-width: 768px) {
  .trade-markit-container {
    flex-direction: column;
  }

  .section1 {
    width: 100%;
    margin-bottom: 20px;
  }

}
.bvtPSA {
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  background-color: rgb(22, 199, 132);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 8px;
}
.me-3{
  background-color: rgb(199, 22, 22);
}
/* .textleft{
  text-align: center;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,0BAA0B;;AAE5B;;AAEA;EACE,WAAW;EACX,+BAA+B;AACjC;;AAEA,sCAAsC;AACtC;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;AAEF;AACA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,mCAAmC;EACnC,oBAAoB;EAEpB,uBAAuB;EAEvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,kCAAkC;AACpC;AACA;;GAEG","sourcesContent":[".trade-markit-container {\n  display: flex;  \n  /* flex-wrap: wrap; */\n  justify-content: space-between;\n  /* margin: 20px; */\n}\n.me-2{\n  margin-left: 10px;\n}\n\n.section1 {\n  width: 32.9%;\n  /* align-items: center; */\n}\n.sectionpadding{\n  align-items: center;\n  margin-bottom: 10px;\n  border-radius:15px ;\n  margin-top: 20px;\n}\n.th-1{\n  padding: 2px 10px 2px 10px;\n\n}\n\ntable {\n  width: 100%;\n  /* border-collapse: collapse; */\n}\n\n/* Responsive design for mobile view */\n@media screen and (max-width: 768px) {\n  .trade-markit-container {\n    flex-direction: column;\n  }\n\n  .section1 {\n    width: 100%;\n    margin-bottom: 20px;\n  }\n\n}\n.bvtPSA {\n  height: 24px;\n  width: 24px;\n  min-width: 24px;\n  border-radius: 12px;\n  color: rgb(255, 255, 255);\n  background-color: rgb(22, 199, 132);\n  display: inline-flex;\n  -webkit-box-pack: center;\n  justify-content: center;\n  -webkit-box-align: center;\n  align-items: center;\n  margin-inline-end: 8px;\n}\n.me-3{\n  background-color: rgb(199, 22, 22);\n}\n/* .textleft{\n  text-align: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
