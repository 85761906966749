import React, { useState, useEffect } from 'react';
import './style.css';

const announcements = [
  {

    title: "JOIN Telegram",
    link: "https://t.me/+14UWFzzimsczOTE1"
  },
  {

    title: "AirDrop comming soon",
    link: ""
  },
];

const NewSlider = () => {
  const [currentAnnouncement, setCurrentAnnouncement] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (announcements.length === 0) return;

    const interval = setInterval(() => {
      setAnimationClass(''); // Remove animation class
      setTimeout(() => {
          setAnimationClass('slide-up-animation'); // Add animation class
        setCurrentAnnouncement((prev) => (prev+1) % announcements.length);
        setAnimationClass('slide-up-animation'); // Add animation class
        // setCurrentAnnouncement((prev) => (+1) % announcements.length);
      }, 0);
    }, 3000); // Change this duration as needed

    return () => clearInterval(interval);
  }, []);

  if (announcements.length === 0) {
    return null;
  }

  const currentData = announcements[currentAnnouncement];

  return (
    <div className="hero-section__announcement">
      <div className="hero-section__announcement__icon">
        <img  className="newpng" src="./images/specker.png"></img>
      </div>
      <a href={currentData.link} >
      <div className="swiper1 swiper-initialized swiper-vertical css-1wjr5z7 swiper-backface-hidden">
        <div className={`swiper-wrapper1 ${animationClass}`}>
          <div className="swiper-slide1 swiper-slide-next css-1c1947m" data-swiper-slide-index={currentAnnouncement}>
            <div className="hero-section__announcement__detail">
              <div className="hero-section__announcement__info">
                <span className="MuiTypography-root MuiTypography-smallCaptions small-сaptions css-1fuiqo3">
               {currentData.title}
                </span>
              </div>
              <div className="hero-section__announcement__link">
                <a href={currentData.link}
                 /*target="_blank" */
                  rel="noreferrer"
                  >
                  <button className="u-btn-learn-more css-x53hod" type="button">
                    <span className="title12">Learn More</span>
                    <span className="icon">
                      <i>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-arrow-right"
                        >
                          <path
                            d="M11.9968 8H14.4321L17.0002 12L14.4321 16H11.9968L13.9229 13H7V11H13.9229L11.9968 8Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </i>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
    </div>
  );
};

export default NewSlider;
