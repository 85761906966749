import React from "react";
import "./Table.css";

const Table = ({ topGainner, topLosser, trending }) => {
  console.log("topGainner", topGainner);
  console.log("topGainner", topLosser);
  console.log("topGainner", trending);

  return (
    <div className="container">
      <div className="trade-markit-container table-responsive ">
        <div className="section1 sectionpadding table-responsive tab-content custom-tab-content p-0">
          <h3 className="positionstatic">
            <span class="bvtPSA me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16px"
                width="16px"
                viewBox="0 0 24 24"
                className="sc-aef7b723-0 fINSSs"
              >
                <path
                  d="M21 7L12.9 16L7.5 10.6L3 15.1"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M14.6992 7H20.9992V13.3"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </span>
            Top Gainers
          </h3>
          <table>
            {/* <title>Biggest Gainers</title> */}
            <thead>
              <tr className="th-1">
                <th className="th-1">Symbol</th>
                <th className="th-1">Price</th>
                <th className="th-1">Change%</th>
              </tr>
            </thead>
            <tbody>
              {topGainner.map((item) => (
                <tr className="th-1" key={item.id}>
                  <td className="th-1">{item.symbol}</td>
                  <td className="th-1">{item.prevClosePrice}</td>
                  <td className="color-green text-success textleft th-1">
                    {item.priceChangePercent}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="section1 sectionpadding table-responsive tab-content custom-tab-content p-0">
          <h3>
            <span class="bvtPSA bvtPSAL me-3 me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16px"
                width="16px"
                viewBox="0 0 24 24"
                class="sc-aef7b723-0 fINSSs"
              >
                <path
                  d="M21 17L12.9 8L7.5 13.4L3 8.9"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M14.7002 17.0002H21.0002V10.7002"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </span>
            Top Losers
          </h3>
          <table>
            <thead>
              <tr className="th-1">
                <th className="th-1">Symbol</th>
                <th className="th-1">Price</th>
                <th className="th-1">Change%</th>
              </tr>
            </thead>
            <tbody>
              {topLosser.map((item) => (
                <tr className="th-1" key={item.id}>
                  <td className="th-1">{item.symbol}</td>
                  <td className="th-1">{item.lastPrice}</td>
                  <td className="color-red text-danger textleft th-1">
                    {item.priceChangePercent}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="section1 sectionpadding table-responsive tab-content custom-tab-content p-0">
          <h3 className="me-2">
            {/* 🔥 */}
            <img src="./images/tranding.png" height={20}></img>
            Trending
          </h3>
          <table>
            <thead>
              <tr className="th-1">
                <th className="th-1">Symbol</th>
                <th className="th-1">Price</th>
                <th className="th-1">Change%</th>
              </tr>
            </thead>
            <tbody>
              {trending.map((item) => (
                <tr className="th-1" key={item.id}>
                  <td className="th-1">{item.symbol}</td>
                  <td className="th-1">{item.price}</td>
                  <td
                    className={
                      item.percentChange24h >= 0
                        ? "color-green text-success  textleft th-1"
                        : "color-red text-danger  textleft th-1"
                    }
                  >
                    {item.percentChange24h}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
