import "./style.css"
const Index = ({ ApiConfig, item ,SwiperSlide ,upperPairData}) => {

    return (
        // <marqu >
        // <div className="css-1qh86rh">
        //     <div className="MuiBox-root css-2kv7k5">
        //         <div className="rfm-marquee-container currencies__row">
        //            <div className="rfm-marquee">
        //                 <div className="rfm-initial-child-container">
        //                     <div className="rfm-child">
        //                         <div className="currencies__item">
        //                             <div className="currencies__article">
        //                                 <div className="currencies__icon">
        //                                 <img width="100%" src="https://api2.azbit.com/api/currencies/image/ETH" alt="Ethereum" className="css-1ev2xom"></img>
        //                                 </div>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__name css-g24lph">ETH/USDT</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__count css-g24lph">3466.2</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__percent green css-g24lph">0.43 %</span>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="rfm-child">
        //                         <div className="currencies__item">
        //                             <div className="currencies__article">
        //                                 <div className="currencies__icon">
        //                                 <img width="100%" src="https://api2.azbit.com/api/currencies/image/ETH" alt="Ethereum" className="css-1ev2xom"></img>
        //                                 </div>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__name css-g24lph">ETH/USDT</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__count css-g24lph">3466.2</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__percent green css-g24lph">0.43 %</span>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="rfm-marquee">
        //                 <div className="rfm-initial-child-container">
        //                     <div className="rfm-child">
        //                         <div className="currencies__item">
        //                             <div className="currencies__article">
        //                                 <div className="currencies__icon">
        //                                 <img width="100%" src="https://api2.azbit.com/api/currencies/image/ETH" alt="Ethereum" className="css-1ev2xom"></img>
        //                                 </div>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__name css-g24lph">ETH/USDT</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__count css-g24lph">3466.2</span>
        //                                 <span className="MuiTypography-root MuiTypography-body400 currencies__percent green css-g24lph">0.43 %</span>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div> </marqu>
        <div className="displayflex">
        <marquee className="marquee" direction="left" scrollamount="5" onmouseover="this.stop()" onmouseout="this.start()" loop="infinite">
            <div className="displayflex marquee_inner">
            {upperPairData.map((item, index) => (
            <SwiperSlide className="marquee_item"
                    style={{
                      backgroundColor: '#1d4e41',
                      margin: '-10px',
                      width: '325px',
                      padding: '10px 0px',
                    }}
                    key={item?.icon_path}
                  >
                    <div>
                      <tr style={{ display: 'flex', alignItems: 'center' }} key={1}>
                        <td style={{ margin: '0px 10px' }}>
                          <div className="td_div">
                            <img
                              alt=""
                              src={ApiConfig.appUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2"
                            />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td style={{ margin: '0px 10px' }}>
                          <b>{item?.buy_price}</b>
                        </td>
                        <td
                          style={{ margin: '0px 10px' }}
                          className={
                            item?.change >= 0
                              ? 'color-green text-success'
                              : 'color-red text-danger'
                          }
                        >
                          <b>{parseFloat(item?.change?.toFixed(5))}%</b>
                        </td>
                      </tr>
                    </div>
                  </SwiperSlide>
            ))}
            {upperPairData.map((item, index) => (
            <SwiperSlide  className="marquee_item"
                    style={{
                      backgroundColor: '#1d4e41',
                      margin: '-10px',
                      width: '325px',
                      padding: '10px 0px',
                    }}
                    key={item?.icon_path}
                  >
                    <div>
                      <tr style={{ display: 'flex', alignItems: 'center' }} key={1}>
                        <td style={{ margin: '0px 10px' }}>
                          <div className="td_div">
                            <img
                              alt=""
                              src={ApiConfig.appUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2"
                            />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td style={{ margin: '0px 10px' }}>
                          <b>{item?.buy_price}</b>
                        </td>
                        <td
                          style={{ margin: '0px 10px' }}
                          className={
                            item?.change >= 0
                              ? 'color-green text-success'
                              : 'color-red text-danger'
                          }
                        >
                          <b>{parseFloat(item?.change?.toFixed(5))}%</b>
                        </td>
                      </tr>
                    </div>
                  </SwiperSlide>
            ))}
            {/* {upperPairData.map((item, index) => (
            <SwiperSlide  className="marquee_item"
                    style={{
                      backgroundColor: '#1d4e41',
                      margin: '-10px',
                      width: '320px',
                      padding: '10px 0px',
                    }}
                    key={item?.icon_path}
                  >
                    <div>
                      <tr style={{ display: 'flex', alignItems: 'center' }} key={1}>
                        <td style={{ margin: '0px 10px' }}>
                          <div className="td_div">
                            <img
                              alt=""
                              src={ApiConfig.appUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2"
                            />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td style={{ margin: '0px 10px' }}>
                          <b>{item?.buy_price}</b>
                        </td>
                        <td
                          style={{ margin: '0px 10px' }}
                          className={
                            item?.change >= 0
                              ? 'color-green text-success'
                              : 'color-red text-danger'
                          }
                        >
                          <b>{parseFloat(item?.change?.toFixed(5))}%</b>
                        </td>
                      </tr>
                    </div>
                  </SwiperSlide>
            ))} */}
            </div>
        </marquee>
        </div>
    );
}
export default Index