import React, { useContext, useEffect, useState } from "react";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../Utils/CustomAlertMessage";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { SocketContext } from "../../Utils/SocketContext";
import Select from "react-select";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../Context";

const BuySell = () => {
  const { kycStatus } = useContext(ProfileContext);
  const [socketData, setSocketData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [secondPriceName, setSecondPriceName] = useState("GapxBit");
  const [getCurrency, setGetCurrency] = useState("");
  const [selectedCurrImg, setSelectedCurrImg] = useState();
  const { socket } = useContext(SocketContext);
  const token = sessionStorage.getItem("CTEX_AUTH_TOKEN");
  const toFixed = (value) => parseFloat(value?.toFixed(6));

  const handleChange = (selectedOption) => {
    setCurrency(selectedOption.value);
    setSelectedCurrImg(selectedOption.image);
    setCurrencyPrice(selectedOption.price);
    setSecondPriceName(selectedOption.secondPrice);
  };

  useEffect(() => {
    if (socket) {
      let payload = {
        message: "market",
      };
      socket.emit("message", payload);
      socket.on("message", (data) => {
        if (data?.pairs.length > 0) {
          let filteredData = data?.pairs?.filter(
            (item) => item?.quote_currency === "GAPX"
          );
          let filteredData2 = data?.pairs
            ?.filter(
              (item) =>
                item?.quote_currency === "USDT" &&
                item?.base_currency === "GAPX"
            )
            ?.map((data) => {
              return {
                ...data,
                base_currency: "USDT",
                quote_currency: "GAPX",
                buy_price: toFixed(1 / data?.buy_price),
                sell_price: toFixed(1 / data?.sell_price),
              };
            });
          setSocketData([...filteredData, ...filteredData2]);
          setSelectedCurrImg(filteredData[0]?.icon_path);
          setCurrency(filteredData[0]?.base_currency);
          setCurrencyPrice(filteredData[0]?.buy_price);
          setSecondPriceName(filteredData[0]?.quote_currency);
        }
      });
    }
  }, [socket]);

  const handleBuySell = async (
    currency,
    secondPriceName,
    side,
    payAmount,
    getCurrency
  ) => {
    if (!payAmount) {
      alertErrorMessage("Please Enter Amount");
      return;
    } else if (payAmount <= 0) {
      alertErrorMessage("Amount must be greater then 0");
      return;
    }
    // return;
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.swapToken(
        currency,
        secondPriceName,
        side,
        +payAmount,
        +getCurrency
      );
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result.message);
        setPayAmount(0);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error.message);
    }
  };

  return (
    <>
      <section className="buy_banner"></section>
      <section className="buy_page mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-lg-5 col-12 mx-auto ">
              <form className="custom-form  card_qbs  ticket-form mb-5 mb-lg-0">
                <div className="ticket-header login-header">
                  <ul
                    className="nav nav-pills login-pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active nav_success"
                        id="pills-Buy-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-Buy"
                        type="button"
                        role="tab"
                        aria-controls="pills-Buy"
                        aria-selected="true"
                        onClick={() => {
                          setPayAmount(0);
                        }}
                      >
                        <span>Buy</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link nav_danger"
                        id="pills-Sell-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-Sell"
                        type="button"
                        role="tab"
                        aria-controls="pills-Sell"
                        aria-selected="false"
                        onClick={() => {
                          setPayAmount(0);
                        }}
                      >
                        <span>Sell</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane show active"
                    id="pills-Buy"
                    role="tabpanel"
                    aria-labelledby="pills-Buy-tab"
                  >
                    <div className="card-body ">
                      <div className="swap_form form-group mb-3">
                        <div className="mb-1 fornewlabel">
                          Choose Cryptocurrency
                        </div>
                        <Select
                          className="custom-select-container"
                          classNamePrefix="custom-select"
                          options={socketData.map((item) => ({
                            value: item.base_currency,
                            image: item.icon_path,
                            label: (
                              <>
                                <img
                                  alt=""
                                  src={ApiConfig?.appUrl + item?.icon_path}
                                  width="20"
                                  height="20"
                                />
                                <span> {item.base_currency} </span>
                              </>
                            ),
                            price: item.buy_price,
                            secondPrice: item.quote_currency,
                          }))}
                          onChange={handleChange}
                          value={{
                            value: currency,
                            label: (
                              <>
                                <img
                                  alt=""
                                  src={ApiConfig?.appUrl + selectedCurrImg}
                                  width="20"
                                  height="20"
                                />
                                <span> {currency} </span>
                              </>
                            ),
                          }}
                        />
                      </div>

                      <div className="balance-libra card-success mb-4">
                        <div className="token-img-bg_right" id="lc-data">
                          {" "}
                          Price:-{" "}
                          <b>
                            1 {currency} = {currencyPrice} {secondPriceName}
                          </b>
                        </div>
                      </div>
                      <div className="field-box mb-2">
                        <label className="form-label" htmlFor="buypayamount">
                          {" "}
                          Pay Amount{" "}
                        </label>
                        <div className=" field-otp-box">
                          <input
                            type="number"
                            name="amount"
                            id="buypayamount"
                            value={payAmount}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              setPayAmount(e.target.value);
                              setGetCurrency(
                                toFixed(e.target.value / currencyPrice)
                              );
                            }}
                          />
                          <span className="show_password opt_btn btn-sm">
                            {secondPriceName}
                          </span>
                        </div>
                      </div>

                      <div className="py-4 d-flex justify-contennt-center text-center">
                        <img
                          alt=""
                          src={ApiConfig?.appUrl + selectedCurrImg}
                          width="25"
                          height="25"
                          className=" mx-auto "
                        />
                      </div>

                      <div className="field-box mb-5">
                        <label className="form-label" htmlFor="buycurrency">
                          {" "}
                          Currency You Get{" "}
                        </label>
                        <div className="field-otp-box">
                          <input
                            type="text"
                            name="amount"
                            id="buycurrency"
                            value={toFixed(payAmount / currencyPrice)}
                            disabled
                          />
                          <span className="show_password opt_btn btn-sm">
                            {currency}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-10 col-12 mx-auto">
                        {token ? (
                          kycStatus != 2 ? (
                            <Link
                              to="/KycPage"
                              className="btn btn-primary w-100 justify-content-center btn-medium"
                            >
                              Verify KYC
                            </Link>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-success w-100 justify-content-center btn-medium"
                              onClick={() =>
                                handleBuySell(
                                  currency,
                                  secondPriceName,
                                  "BUY",
                                  payAmount,
                                  getCurrency
                                )
                              }
                            >
                              Buy {currency}
                            </button>
                          )
                        ) : (
                          <Link
                            to="/login"
                            className="btn custom-btn btn-primary justify-content-center w-100 my-1 my-md-0"
                          >
                            Log In/Sign Up
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="pills-Sell"
                    role="tabpanel"
                    aria-labelledby="pills-Sell-tab"
                  >
                    <div className="card-body ">
                      <div className="swap_form form-group mb-3">
                        <div className="mb-1 fornewlabel">
                          Choose Cryptocurrency
                        </div>
                        <Select
                          className="custom-select-container"
                          classNamePrefix="custom-select"
                          options={socketData.map((item) => ({
                            value: item.base_currency,
                            image: item.icon_path,
                            label: (
                              <>
                                <img
                                  alt=""
                                  src={ApiConfig?.appUrl + item?.icon_path}
                                  width="20"
                                  height="20"
                                />
                                <span> {item.base_currency} </span>
                              </>
                            ),
                            price: item.buy_price,
                            secondPrice: item.quote_currency,
                          }))}
                          onChange={handleChange}
                          value={{
                            value: currency,
                            label: (
                              <>
                                <img
                                  alt=""
                                  src={ApiConfig?.appUrl + selectedCurrImg}
                                  width="20"
                                  height="20"
                                />
                                <span> {currency} </span>
                              </>
                            ),
                          }}
                        />
                      </div>
                      <div className="balance-libra card-success mb-4">
                        <div className="token-img-bg_right">
                          <div className="token-img-bg_right" id="lc-data">
                            {" "}
                            Price:-{" "}
                            <b>
                              1 {currency} = {currencyPrice} {secondPriceName}
                            </b>
                          </div>
                        </div>
                      </div>
                      <div className="swap_form form-group mb-2">
                        <label className="form-label" htmlFor="payamount">
                          {" "}
                          Pay Amount{" "}
                        </label>
                        <div className=" field-otp-box">
                          <input
                            type="number"
                            name="amount"
                            id="payamount"
                            value={payAmount}
                            onChange={(e) => {
                              setPayAmount(e.target.value);
                              setGetCurrency(
                                toFixed(e.target.value * currencyPrice)
                              );
                            }}
                            onWheel={(e) => e.target.blur()}
                          />
                          <span className="show_password opt_btn btn-sm ">
                            {currency}
                          </span>
                        </div>
                      </div>

                      <div className="py-4 d-flex justify-contennt-center text-center">
                        <img
                          alt=""
                          src={ApiConfig?.appUrl + selectedCurrImg}
                          width="25"
                          height="25"
                          className=" mx-auto "
                        />
                      </div>

                      <div className="swap_form form-group mb-5">
                        <label className="form-label" htmlFor="currencyget">
                          {" "}
                          Currency You Get{" "}
                        </label>
                        <div className="field-otp-box">
                          <input
                            type="text"
                            name="amount"
                            id="currencyget"
                            value={toFixed(payAmount * currencyPrice)}
                            disabled
                          />
                          <span className="show_password opt_btn btn-sm ">
                            {secondPriceName}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-10 col-12 mx-auto">
                        {token ? (
                          kycStatus != 2 ? (
                            <Link
                              to="/KycPage"
                              className="btn btn-primary w-100 justify-content-center btn-medium"
                            >
                              Verify KYC
                            </Link>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger w-100 justify-content-center btn-medium"
                              onClick={() =>
                                handleBuySell(
                                  currency,
                                  secondPriceName,
                                  "SELL",
                                  payAmount,
                                  getCurrency
                                )
                              }
                            >
                              Sell {currency}
                            </button>
                          )
                        ) : (
                          <Link
                            to="/login"
                            className="btn custom-btn btn-primary justify-content-center w-100 my-1 my-md-0"
                          >
                            Log In/Sign Up
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BuySell;
