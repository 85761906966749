// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marginright{
    /* color: rgb(255, 255, 0); */
    margin-right: 10px;
    padding-right: 20%;
   
}`, "",{"version":3,"sources":["webpack://./src/Components/MarketPage/style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,kBAAkB;;AAEtB","sourcesContent":[".marginright{\n    /* color: rgb(255, 255, 0); */\n    margin-right: 10px;\n    padding-right: 20%;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
